import { Layout } from "antd";
const {Footer} = Layout;
import styles from "../../styles/Home.module.css";
function ZFooter(params) {
	return (
		<Footer className={styles.footer}>
			{/* 第一个 */}
			<a
				href="https://zstore.ml"
				target="_blank"
				rel="noopener noreferrer"
				style={{ marginRight: "10px" }}
				className={styles.footerTime}
			>
				© 2019-
				<span style={{ marginLeft: "5px", fontWeight: "bolder" }}>
					2023
				</span>
			</a>
			{/* 第二个 */}
			<a
				href="https://www.netlify.com"
				target="_blank"
				rel="noopener noreferrer"
				style={{ marginRight: "20px" }}
				className={styles.footerAuthor}
			>
				Created by
				<span style={{ marginLeft: "5px", fontWeight: "bolder" }}>
					Zevin
				</span>
			</a>
			{/* 第三个 */}
			<a
				href="https://www.netlify.com"
				target="_blank"
				rel="noopener noreferrer"
				style={{ marginRight: "10px" }}
				className={styles.footerPower}
			>
				Powered by{""}
				<span style={{ marginLeft: "5px", fontWeight: "bolder" }}>
					💖 Netlify
				</span>
			</a>
		</Footer>
	);
}

export default ZFooter;
